// Override default variables before the import
// $body-bg: #727070;
$body-bg: #000000;

$theme-colors: (
  "primary": #000,
  "secondary": #f9fa89f1,
  "gray": #727070d3,
  "yellow": #dddddd
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

